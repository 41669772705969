// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;
import * as bootstrap from 'bootstrap'

import Turbolinks from "turbolinks"
import Rails from "@rails/ujs";
import "sumoselect/jquery.sumoselect"
import DataTable from "vanilla-datatables/src/vanilla-dataTables"
import ApexCharts from 'apexcharts';
import tippy from 'tippy.js';

window.ApexCharts = ApexCharts;

Rails.start();
Turbolinks.start();





var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new bootstrap.Tooltip(tooltipTriggerEl)
})

tippy('[data-tippy-content]', {allowHTML: true});



$(document).on('turbolinks:load', function() {
  var theme = localStorage.getItem('theme');  
  if(theme == 'theme-dark') {
    $('.mode').show();
    $('.dark').hide();
  }
  $('body').addClass(theme);

  tippy('[data-tippy-content]', {allowHTML: true});

  $(".mode").click(function(e){
    e.preventDefault();
    if($(this).hasClass('dark')) {
      $('body').addClass('theme-dark')
      $('.mode').show();
      $('.dark').hide();
      localStorage.setItem('theme', 'theme-dark');  
      Turbolinks.clearCache();
    } else {
      $('body').removeClass('theme-dark')
      $('.mode').hide();
      $('.dark').show();
      localStorage.setItem('theme', ' ');  
      Turbolinks.clearCache();
    }
  })
  $(document).on('input propertychange', '#scrubnumbers', function () {
    var lines = $(this).val().split(/\r|\r\n|\n/);
    var count = lines.length;
    $("#numberoflines").html(`${count} numbers in list`);
  });

  
  $('#search_states').SumoSelect({selectAll: true});




	var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  })

  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })

	$('form').on('submit', function(event) {
  		$(this).find("button[type=submit]").prop('disabled', true);
  		$(this).find("button[type=submit]").html("Please wait...")
	});

  
  $('#deleteall').click(function(){
        if($(this).is(':checked')){
          $('.checkmate').prop("checked",true)
        }
        else{
          $('.checkmate').prop("checked",false)
        }
  });


  $('input[type=checkbox]').on('click', function(){
      if ($(".checkmate:checked").length > 0) {
        $(".delete-btn").removeClass('d-none')
        $(".delete-btn small").html($(".checkmate:checked").length)
      } else {
        $(".delete-btn small").html($(".checkmate:checked").length)
        $(".delete-btn").addClass('d-none')
      }
    })

  $('#iconfirm').on('input', function(){
    var value = $(this).val();
    if (value == 'confirm') {
      $('.delete-confirm').removeAttr('disabled');
    } else {
      $('.delete-confirm').prop('disabled', true);
    }
  });

  $("#checkalltfn").click(function(){
    $("input[name='cart[]']").not(this).prop('checked', this.checked);
  });
	$("#checkall").click(function(){
    $("input[name='cart']").not(this).prop('checked', this.checked);
    var data = []
    $("input[name='cart']").not(this).each(function(){
      if($(this).prop('checked')){
        data.push($(this).data('params'))
      }
    })
    console.log(data);
    if(data) { 
      $.ajax({
          url:'/cart/bulk_add',
          type:'POST',
          dataType:'json',
          data:{
              dids: data,
              authenticity_token: $('meta[name=csrf-token]').attr('content')
          },
          success:function(data){
              $("input[name='cart']").last().trigger('click');
          },
          error:function(data){
              $("input[name='cart']").last().trigger('click');
          }
      });
    }

    // $("input[name='cart']").trigger('click');
    // $('tr').each(function(){ $(this).click(); })
    // updateCart();
	});


	$(":checkbox").on('change', function() {
    if($(this).prop('checked')) {
    	$(this).parent().parent().addClass("checked");
    } else {
    	$(this).parent().parent().removeClass("checked");
    }
    // updateCart();
	});

	
	$( "[data-row]" ).on( "click", function (event) {
		var row = $(this)
		if (event.target.type !== 'checkbox') {
			// row.toggleClass("checked");
      $(':checkbox', this).trigger('click');
    }
	});

	$( document ).on( "click", "#remove-from-cart", function (event) {
    event.preventDefault();
		var did = $(this).data('number');
		$(`#${did}`).click();
	});

  var table = document.getElementById("users-table");
  if(table){
    var dataTable = new DataTable(table, {
      fixedHeight: true,
      searchable: true,
      sortable: true,
      perPage: 100
    });
  }

  $("tr[data-link]").click(function() {
    window.location = $(this).data("link")
  })


})


$(function() {
  var modal_holder_selector, modal_selector;
  modal_holder_selector = '#modal-holder';
  modal_selector = '.modal';

  $(document).on('click', 'a[data-modal]', function() {
    $('.modal-backdrop').remove();
    var location;
    var options = {};
    var allow_close;
    location = $(this).attr('href');
    allow_close = $(this).data('allowclose');
    if (allow_close == false) {
      options = {backdrop: 'static'}
    }
    $.get(location, function(data) {
      console.log(data)
      
      
      $(modal_holder_selector).html(data)
      var myModal = new bootstrap.Modal(document.getElementById('mainModal'), options)
      return myModal.toggle();
    });

    return false;
  });

  return $(document).on('ajax:success', 'form[data-modal]', function(event) {

    var detail = event.detail;
    var data = detail[0], status = detail[1],  xhr = detail[2];
    var url;
    url = xhr.getResponseHeader('Location');

      if(url) {
        window.location.reload();
      }
      $('.modal-backdrop').remove();
      $(modal_holder_selector).html(xhr.response)
      var myModal = new bootstrap.Modal(document.getElementById('mainModal'))
      myModal.toggle();
    return false;
  });
});